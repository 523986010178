<template>
    <div class="baseMain">
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne userTeacherML" style="margin-left: 10px;">
                <el-button type="primary" @click="openAddOne('new')">新增</el-button>
            </div>
            <div class="baseOperateOne userTeacherML">
                <el-button type="primary" @click="openTmpl">从模板库选择</el-button>
            </div>
        </div>
        <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="batchForm">
            <el-table-column prop="name" label="名称" width="180"></el-table-column>
            <el-table-column label="内容" width="320">
                <template #default="scope">
                    <template v-if="scope.row.type == 'text'">
                        <el-input :placeholder="'请输入'+scope.row.name" style="width: 200px;"></el-input>
                    </template>
                    <template v-if="scope.row.type == 'select'">
                        <el-select v-model="scope.row.selOne" placeholder="请选择" style="width: 200px;">
                            <el-option v-for="item in scope.row.options" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                        <el-link class="formML" type="success" @click.prevent="openAddSelectMsg(scope.row)">
                            管理数据源
                        </el-link>
                    </template>
                    <template v-if="scope.row.type == 'image'">
                        <el-upload style="width: 400px;" ref="importUpload" multiple
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :on-change="importHandleChange" :before-remove="importHandleChange"
                                   :file-list="importForm.importFileList"
                                   :auto-upload="false" :on-exceed="importHandleExceed"
                                   list-type="picture" accept="image/png,image/jpg,image/jpeg"
                                   :limit="importForm.importFileLimit">
                            <el-button type="primary">选取文件</el-button>
                            <template #tip>
                                <div class="el-upload__tip">请上传jpg、jpeg、png格式图片，大小不能超过1M</div>
                            </template>
                        </el-upload>
                    </template>
                    <template v-if="scope.row.type == 'datetime'">
                        <el-date-picker type="date" :placeholder="'请选择'+scope.row.name"
                                        value-format="YYYY-MM-DD" style="width: 200px;"></el-date-picker>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="是否必填">
                <template #default="scope">
                    <el-tag type="success" v-if="scope.row.is_required == 1">必填</el-tag>
                    <el-tag type="warning" v-else>非必填</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="是否显示">
                <template #default="scope">
                    <el-tag type="success" v-if="scope.row.status == 1">显示</el-tag>
                    <el-tag type="warning" v-else>不显示</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序(数字大在上)"></el-table-column>
            <el-table-column prop="cate" label="分类">
                <template #default="scope">
                    {{ cateFilter(scope.row.cate) }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <template v-if="noEditList.indexOf(scope.row.key+'') == -1">
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openAddOne('edit',scope.row)">
                            编辑
                        </el-link>
                        <el-link class="tableButton" type="danger"
                                 @click.prevent="addOneDel(scope.row)">
                            删除
                        </el-link>
                    </template>
                    <el-link v-if="scope.row.key == 'phone'" class="tableButton" type="primary"
                             @click.prevent="openAddOne('edit',scope.row)">
                        编辑
                    </el-link>
                </template>
            </el-table-column>
        </publicTable>
        <!--<el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="100px">-->
        <!--<template v-for="item in batchForm" :key="item.key">-->
        <!--<el-form-item :label="item.name">-->
        <!--<template v-if="item.type == 'text'">-->
        <!--<el-input v-model="item.def_value" placeholder="请输入姓名" style="width: 200px;"></el-input>-->
        <!--</template>-->
        <!--<template v-if="item.type == 'select'">-->
        <!--<el-select v-model="item.selOne" placeholder="请选择" style="width: 200px;">-->
        <!--<el-option v-for="item2 in item.options" :key="item2.value" :label="item2.label"-->
        <!--:value="item2.value"></el-option>-->
        <!--</el-select>-->
        <!--<el-button type="success" class="formML" @click="openAddSelectMsg(item)">管理数据源-->
        <!--</el-button>-->
        <!--</template>-->
        <!--<el-radio-group disabled v-model="item.is_required" class="formML">-->
        <!--<el-radio-button :label="1">必填</el-radio-button>-->
        <!--<el-radio-button :label="0">非必填</el-radio-button>-->
        <!--</el-radio-group>-->
        <!--<el-radio-group disabled v-model="item.status" class="formML">-->
        <!--<el-radio-button :label="1">显示</el-radio-button>-->
        <!--<el-radio-button :label="0">不显示</el-radio-button>-->
        <!--</el-radio-group>-->
        <!--<el-input-number disabled v-model="item.sort" controls-position="right"></el-input-number>-->
        <!--<el-button type="danger" class="formML" @click="openAddOne('edit',item)">编辑</el-button>-->
        <!--<el-button type="danger" class="formML" @click="addOneDel(item)">删除</el-button>-->
        <!--</el-form-item>-->
        <!--</template>-->
        <!--<el-form-item label=" ">-->
        <!--<el-button type="primary" @click="openAddOne('new')">新增</el-button>-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--<div class="signupPSRightButtonOne">-->
        <!--<el-button type="primary" size="large" style="width: 160px;" @click="saveAll">保存</el-button>-->
        <!--</div>-->
        <el-dialog :title="addOneTitle" v-model="addOneDialog" width="600px" :close-on-click-modal="false">
            <el-form :model="addOneForm" :rules="addOneFormRules" ref="addOneForm" label-width="200px">
                <template v-if="addOneTitle == '编辑' && selMsg.key == 'phone'">
                    <el-form-item label="是否必填">
                        <el-radio-group v-model="addOneForm.is_required">
                            <el-radio-button label="1">必填</el-radio-button>
                            <el-radio-button label="0">非必填</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </template>
                <tempalte v-else>
                    <el-form-item label="类型" prop="type">
                        <el-radio-group v-model="addOneForm.type">
                            <el-radio label="text">输入框</el-radio>
                            <el-radio label="select">下拉框</el-radio>
                            <el-radio label="image">图片上传</el-radio>
                            <el-radio label="datetime">时间选择</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="addOneForm.name" placeholder="请输入名称" style="width: 200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="是否必填">
                        <el-radio-group v-model="addOneForm.is_required">
                            <el-radio-button label="1">必填</el-radio-button>
                            <el-radio-button label="0">非必填</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否显示">
                        <el-radio-group v-model="addOneForm.status">
                            <el-radio-button label="1">显示</el-radio-button>
                            <el-radio-button label="0">不显示</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input-number v-model="addOneForm.sort" controls-position="right" :min="0"
                                         :max="99"></el-input-number>
                    </el-form-item>
                    <el-form-item label="分类" prop="cate">
                        <!-- <el-input v-model="addOneForm.cate" placeholder="请输入分类" style="width: 200px;"></el-input> -->
                        <el-select v-model="addOneForm.cate" filterable clearable placeholder="请选择分类" style="width: 200px;">
                            <el-option v-for="item in FormTypeList" :key="item.id" :label="item.label"
                                    :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </tempalte>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveAddOne">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="管理下拉框数据源" v-model="selectMsgDialog" width="600px" :close-on-click-modal="false">
            <el-form label-width="200px">
                <el-form-item label="内容">
                    <div class="peopleList">
                        <el-tag style="margin: 4px;" v-for="(item,index) in dynamicTags" :key="index" closable
                                :disable-transitions="false" @close="handleClose(index)">
                            {{item}}
                        </el-tag>
                    </div>
                </el-form-item>
                <el-form-item label="新增内容">
                    <el-input v-model="dynamicTagOne" placeholder="请输入内容" style="width: 200px;"></el-input>
                    <el-button type="success" @click="dynamicTagOneAdd" style="margin-left: 10px;">新增</el-button>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="addSelectMsgSave">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="模板库" v-model="tmplDialog" width="1000px" :close-on-click-modal="false">
            <publicTable :publicTableOtherHeight="searchHeight+200" :publicTableData="tmplData">
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column label="内容" width="240">
                    <template #default="scope">
                        <template v-if="scope.row.type == 'text'">
                            <el-input :placeholder="'请输入'+scope.row.name" style="width: 200px;"></el-input>
                        </template>
                        <template v-if="scope.row.type == 'select'">
                            <el-select v-model="scope.row.selOne" placeholder="请选择" style="width: 200px;">
                                <el-option v-for="item in scope.row.options" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                            </el-select>
                            <el-link class="formML" type="success" @click.prevent="openAddSelectMsg(scope.row)">
                                管理数据源
                            </el-link>
                        </template>
                        <template v-if="scope.row.type == 'image'">
                            <el-upload style="width: 400px;" ref="importUpload" multiple
                                    action="https://jsonplaceholder.typicode.com/posts/"
                                    :on-change="importHandleChange" :before-remove="importHandleChange"
                                    :file-list="importForm.importFileList"
                                    :auto-upload="false" :on-exceed="importHandleExceed"
                                    list-type="picture" accept="image/png,image/jpg,image/jpeg"
                                    :limit="importForm.importFileLimit">
                                <el-button type="primary">选取文件</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">请上传jpg、jpeg、png格式图片，大小不能超过1M</div>
                                </template>
                            </el-upload>
                        </template>
                        <template v-if="scope.row.type == 'datetime'">
                            <el-date-picker type="date" :placeholder="'请选择'+scope.row.name"
                                            value-format="YYYY-MM-DD" style="width: 200px;"></el-date-picker>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.is_required == 1">必填</el-tag>
                        <el-tag type="warning" v-else>非必填</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否显示">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.status == 1">显示</el-tag>
                        <el-tag type="warning" v-else>不显示</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序(数字大在上)" width="140"></el-table-column>
                <el-table-column prop="cate" label="分类">
                    <template #default="scope">
                        {{ cateFilter(scope.row.cate) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary"
                                @click.prevent="openSelect(scope.row)">
                            选择
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </el-dialog>
    </div>
</template>

<script>

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import publicTable from '../../components/publicTable.vue'

    import {GetUrlParam} from '../../utils/utils'

    export default {
        name: "signupCustomForm",
        components: {
            publicTable
        },
        data() {
            return {
                noEditList: ['name', 'id_card', 'phone', 'age', 'sex'],
                searchHeight: 0,
                batchForm: [],
                tmplData: [],
                addOneTitle: '',
                addOneDialog: false,
                tmplDialog: false,
                tmplForm: [],
                formType: 2,
                FormTypeList: [],
                addOneForm: {
                    type: '',
                    name: '',
                    is_required: '1',
                    status: '1',
                    sort: 0,
                    cate: '',
                    form_id: ''
                },
                addOneFormRules: {
                    type: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    cate: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                selectMsgDialog: false,
                selMsg: null,
                dynamicTags: [],
                dynamicTagOne: '',
                importForm: {
                    importFileList: [],
                    importFileLimit: 1,
                    importUploadList: [],
                },
            }
        },
        created() {
            this.addOneForm.form_id = GetUrlParam('form_id')
            this.getTableData()
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight - 30;
                this.searchHeight = searchHeight;
                this.getCustomizeFormList();
            })
        },
        methods: {
            openTmpl() {
                let url = '/admin/v1/inputs';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let a = res.data.data;
                        a.forEach(item => {
                            if (item.type == 'select' && item.def_value != null) {
                                let options = [];
                                let newArray = item.def_value.split('/');
                                newArray.forEach(item2 => {
                                    options.push({
                                        value: item2,
                                        label: item2
                                    })
                                })
                                item.options = options;
                                item.selOne = '';
                            }
                        })
                        this.tmplData = a;
                        this.tmplDialog = true
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openSelect(val) {
                this.$confirm('是否选择此组件？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/inputs';
                    let data = {
                        type: val.type,
                        name: val.name,
                        is_required: val.is_required,
                        status: val.status,
                        sort: val.sort,
                        cate: val.cate+'',
                        def_value: val.def_value,
                        form_id: this.addOneForm.form_id
                    }
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.tmplDialog = false;
                            this.getCustomizeFormList();
                        }
                    })
                }).catch(() => {})
            },
            // 
            cateFilter(val) {
                let str = ''
                this.FormTypeList.forEach(item => {
                    if(item.value === val) {
                        str = item.label
                    }
                })
                return str
            },
            getTableData() {
                let url = '/admin/v1/category?type=' + this.formType;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let FormTypeList = [];
                        res.data.data.forEach(item => {
                            let a = {
                                value: item.id + '',
                                label: item.name
                            }
                            FormTypeList.push(a)
                        })
                        this.FormTypeList = FormTypeList;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            addSelectMsgSave() {
                let url = '/admin/v1/inputs/' + this.selMsg.id;
                let data = {
                    def_value: this.dynamicTags.join('/'),
                    form_id: this.addOneForm.form_id
                }
                businessPut(url, data).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success'
                        });
                        this.selectMsgDialog = false;
                        this.getCustomizeFormList();
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            handleClose(index) {
                this.dynamicTags.splice(index, 1);
            },
            dynamicTagOneAdd() {
                if (this.dynamicTagOne != '') {
                    this.dynamicTags.push(this.dynamicTagOne);
                    this.dynamicTagOne = '';
                }
                else {
                    this.$message({
                        showClose: true,
                        message: '请填写内容',
                        type: 'warning'
                    });
                }
            },
            openAddSelectMsg(val) {
                this.selMsg = val;
                if (this.selMsg.def_value == null) {
                    this.dynamicTags = [];
                }
                else {
                    this.dynamicTags = this.selMsg.def_value.split('/');
                }
                this.selectMsgDialog = true;
            },
            addOneDel(item) {
                this.$confirm('此操作将删除该条数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/inputs/' + item.id;
                    let data = {}
                    businessDel(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getCustomizeFormList();
                        }
                    })
                }).catch(() => {})
            },
            saveAddOne() {
                this.$refs.addOneForm.validate((valid) => {
                    if (valid) {
                        let url = '/admin/v1/inputs';
                        let data = {
                            type: this.addOneForm.type,
                            name: this.addOneForm.name,
                            is_required: this.addOneForm.is_required,
                            status: this.addOneForm.status,
                            sort: this.addOneForm.sort,
                            cate: this.addOneForm.cate,
                            form_id: this.addOneForm.form_id
                        }
                        if (this.addOneTitle == '新建') {
                            data.def_value = '';
                            businessPost(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.addOneDialog = false;
                                    this.getCustomizeFormList();
                                }
                            })
                        }
                        else if (this.addOneTitle == '编辑') {
                            url = url + '/' + this.selMsg.id;
                            businessPut(url, data).then(res => {
                                if (res.data.status == 200) {
                                    this.$message({
                                        showClose: true,
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.addOneDialog = false;
                                    this.getCustomizeFormList();
                                }
                            })
                        }
                    }
                })
            },
            getCustomizeFormList() {
                let url = '/admin/v1/inputs';
                if(this.addOneForm.form_id !== '') {
                    url = url + '?form_id=' + this.addOneForm.form_id
                }
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let a = res.data.data;
                        a.forEach(item => {
                            if (item.type == 'select' && item.def_value != null) {
                                let options = [];
                                let newArray = item.def_value.split('/');
                                newArray.forEach(item2 => {
                                    options.push({
                                        value: item2,
                                        label: item2
                                    })
                                })
                                item.options = options;
                                item.selOne = '';
                            }
                        })
                        this.batchForm = a;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openAddOne(sort, val) {
                this.addOneForm.type = '';
                this.addOneForm.name = '';
                this.addOneForm.is_required = '1';
                this.addOneForm.status = '1';
                this.addOneForm.sort = 0;
                this.addOneForm.cate = '';
                if (sort == 'new') {
                    this.addOneTitle = '新建'
                    this.addOneDialog = true;
                }
                else if (sort == 'edit') {
                    this.addOneTitle = '编辑'
                    this.selMsg = val;
                    this.addOneForm.type = this.selMsg.type;
                    this.addOneForm.name = this.selMsg.name;
                    this.addOneForm.is_required = this.selMsg.is_required;
                    this.addOneForm.status = this.selMsg.status;
                    this.addOneForm.sort = this.selMsg.sort;
                    this.addOneForm.cate = this.selMsg.cate;
                    this.addOneDialog = true;
                }
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .formML {
        margin-left: 10px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }

    .avatar {
        width: 120px;
        height: 120px;
        display: block;
    }

</style>
